export default {
  name: 'occupancyPax',
  props: {
    msg: String,
    dropdownShow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: this.$route.query,
    };
  },
  watch: {
    adult() {
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    child() {
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    infant() {
      this.$emit('changeBodyCount', {
        adult: this.adult,
        child: this.child,
        infant: this.infant,
      });
    },
    dropdownShow() {
      // if (this.dropdownShow) { this.$refs.dropdown.show(true); } else { this.$refs.dropdown.hide(true); }
    },
  },
  computed: {
    allCount() {
      return this.adult + this.child + this.infant;
    },
    adult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult
          ? this.$store.getters.GET_SEARCH_CONTENT.adult
          : 2;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    child: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child
          ? this.$store.getters.GET_SEARCH_CONTENT.child
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    infant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant
          ? this.$store.getters.GET_SEARCH_CONTENT.infant
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
  },
  mounted() {
    const adult = (this.query.adult) ? Number(this.query.adult) : 2;
    const child = (this.query.child) ? Number(this.query.child) : 0;
    const infant = (this.query.infant) ? Number(this.query.infant) : 0;

    setTimeout(() => {
      this.adult = adult;
      this.child = child;
      this.infant = infant;
    }, 500);
  },
};
